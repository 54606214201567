import React from "react"
import { Container } from "react-bootstrap"
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const EmbedGoogleVideo = ({ videoId, title }) => (
  <section className="component embedGoogleVideo">
    <Container fluid>
      <div className="col-12 col-md-6 mx-auto text-center">
        <LiteYouTubeEmbed 
          id={videoId}
          title={title}
          poster="maxresdefault"
        />
      </div>
    </Container>
  </section>
)

export default EmbedGoogleVideo
